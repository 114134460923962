import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid/models";

import GridTable from "../../../components/grid";
import ComponentSummary from "../../newCase/summary/componentSummary";
import ConfirmationDialog from "../../newCase/summary/confirmationDialog";
import ServiceContacts from "./serviceContacts";
import Loader from "../../../components/loader";
import useAttorneys from "../../../apis/firm/useAttorneys";
import usePaymentAccounts from "../../../apis/miscellaneous/usePaymentAccounts";
import useLocationFilingTypes from "../../../apis/common/useLocationFilingTypes";
import useSubsequentFiling from "../../../apis/existingCase/useSubsequentFiling";
import { isIndividualUser, renderAmount } from "../../../utills";
import { NotificationContext } from "../../../context/NotificationContext";
import {
  IAttorney,
  ICaseResponse,
  ICrossReferenceNumber,
  IFeeCalculationRequest,
  IFilingInformation,
  IParty,
  IServiceResponse,
} from "../../../interfaces";
import { format } from "date-fns";
import useLocations from "../../../apis/common/useLocations";
import useDisclaimerRequirement from "../../../apis/common/useDisclaimerRequirement";

interface ISummary {
  caseInfo: ICaseResponse;
  parties: IParty[];
  crossReferenceNumbers: ICrossReferenceNumber[];
  filingInfo: IFilingInformation;
  serviceContacts: IServiceResponse[];
  selectedServiceContacts: string[];
  formatToFeeCalculation: (
    filingsInfo: IFilingInformation,
    paymentAccount: string,
    filingAttorneyId?: string,
    partyResponsibleForFees?: number | string,
    attorneys?: IAttorney[]
  ) => IFeeCalculationRequest | undefined;
  updatePayment: (
    tabIndex: number,
    paymentAccount: string,
    filingAttorney: string,
    partyResponsibleForFees: number | string
  ) => void;
  draftEnvelopeId: string
}

const Summary: FC<ISummary> = ({
  caseInfo,
  parties,
  crossReferenceNumbers,
  filingInfo,
  selectedServiceContacts,
  serviceContacts,
  formatToFeeCalculation,
  updatePayment,
  draftEnvelopeId
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();

  const { data: locations } = useLocations();
  const { data: filingTypes } = useLocationFilingTypes(
    caseInfo.locationId,
    caseInfo.caseCatCode,
    caseInfo.caseTypeCode,
    "subsequent"
  );
  const { data: disclaimerRequirement } = useDisclaimerRequirement(
    caseInfo.locationId
  );
  const { data: attorneys } = useAttorneys();
  const { data: paymentAccounts } = usePaymentAccounts();
  const subsequentFiling = useSubsequentFiling();
  const { createNotification } = useContext(NotificationContext);

  const [envelopeId, setEnvelopeId] = useState<number>();
  const [paymentAccount, setPaymentAccount] = useState(
    filingInfo.paymentAccount
  );
  const [filingAttorney, setFilingAttorney] = useState(
    filingInfo.filingAttorney
  );
  const [partyResponsibleForFees, setPartyResponsibleForFees] = useState<
    number | string
  >(filingInfo.partyResponsibleForFeesIndex || "");
  const [isHearingDate, setIsHearingDate] = useState(false);

  const attorneyName = useMemo(() => {
    const attorney = attorneys?.find(
      (attorney) => attorney.attorneyID === filingInfo.filingAttorney
    );

    if (attorney) return `${attorney.firstName} ${attorney.lastName}`;
    else return "-";
  }, [attorneys, filingInfo.filingAttorney]);

  const crossReferenceColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: "efmCrossreferencecodeName",
        headerName: "Case Cross Reference Type",
        width: 750,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "userCrossReferenceNumber",
        headerName: "Case Cross Reference Number",
        width: 700,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
    ],
    []
  );

  const partyColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: "name",
        headerName: "Party Type",
        width: 200,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "party_name",
        headerName: "Name",
        width: 200,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          if (params.row.type === "person")
            return `${params.row.firstName} ${params.row.middleName || ""} ${
              params.row.lastName
            }`;

          return params.row.businessName;
        },
      },
      {
        field: "address",
        headerName: "Address",
        width: 450,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <span>
              {params.row.streetAddressLine1 || ""}
              {params.row.streetAddressLine1 && <br />}
              {params.row.streetAddressLine2
                ? params.row.streetAddressLine2 + " "
                : ""}
              {params.row.city ? params.row.city + ", " : ""}
              {params.row.stateCode ? params.row.stateCode + " " : ""}
              {params.row.zipCode || ""}
            </span>
          );
        },
      },
      {
        field: "phoneNumber",
        headerName: "Phone",
        width: 200,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "leadAttorney",
        headerName: "Attorney",
        width: 200,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          if (!params.row.leadAttorney) return "";

          const attorney = attorneys?.find(
            (attorney) => attorney.attorneyID === params.row.leadAttorney
          );
          if (!attorney) return "";
          return `${attorney.firstName} ${attorney.lastName}`;
        },
      },
    ],
    [attorneys]
  );

  const isWaiverPayment = useMemo(() => {
    return (
      paymentAccounts?.some(
        (item) =>
          item.paymentAccountTypeCode === "WV" &&
          item.paymentAccountID === paymentAccount
      ) || false
    );
  }, [paymentAccount, paymentAccounts]);

  const changeTab = useCallback(
    (tabIndex: number) => {
      if (!paymentAccount) return;

      if (!isIndividualUser() && !filingAttorney) return;

      updatePayment(
        tabIndex,
        paymentAccount,
        filingAttorney || "",
        partyResponsibleForFees
      );
    },
    [paymentAccount, filingAttorney, partyResponsibleForFees, updatePayment]
  );

  const updatePaymentAccount = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPaymentAccount(e.target.value);
    },
    []
  );

  const updateFilingAttorney = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFilingAttorney(e.target.value);
    },
    []
  );

  const updatePartyResponsibleForFees = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPartyResponsibleForFees(parseInt(e.target.value));
    },
    []
  );

  const goToDashboard = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  const createCase = useCallback(() => {
    setIsDisabled(!isDisabled);
    if (!paymentAccount) return;

    if (!isIndividualUser() && !attorneys?.length && !filingAttorney) return;

    const requestObject = formatToFeeCalculation(
      filingInfo,
      paymentAccount,
      filingAttorney,
      partyResponsibleForFees,
      attorneys
    );

    if (requestObject) {
      subsequentFiling.mutate(requestObject);
    }

    const isHearingDate = locations?.find(
      (node) => node.efm_Location_Code === caseInfo.locationId
    )?.ishearingdate;
    setIsHearingDate(isHearingDate || false);
  }, [
    attorneys,
    paymentAccount,
    filingAttorney,
    filingInfo,
    partyResponsibleForFees,
    subsequentFiling,
    formatToFeeCalculation,
    locations,
    caseInfo,
  ]);

  useEffect(() => {
    if (subsequentFiling.isSuccess) {
      setEnvelopeId(subsequentFiling.data);
    }
    // eslint-disable-next-line
  }, [subsequentFiling.isSuccess]);

  useEffect(() => {
    if (subsequentFiling.isError) {
      createNotification({
        message:
          // @ts-ignore
          subsequentFiling.error?.response?.data?.userMessage ||
          (typeof subsequentFiling.error?.response?.data === "string"
            ? subsequentFiling.error?.response?.data
            : null) ||
          "An Error occurred while creating new case!",
        type: "error",
        title: "Existing Case Notification",
      });
    }
    // eslint-disable-next-line
  }, [subsequentFiling.isError, createNotification]);

  return (
    <Box sx={{ p: 0 }}>
      {subsequentFiling.isLoading && <Loader />}

      <div className="filings-row">
        <div className="d-flex justify-content-center">
          <span className="case-id-title">
            {`CASE # ${caseInfo.caseNumber} (${caseInfo.jurisdiction}) - ${caseInfo.description}`}
          </span>
        </div>
      </div>

      <div className="summary-row">
        <div className="existing-case-summary mt-2">
          <h3 className="dash-title-h3 mb-3 theme-blue">Summary</h3>
          <div className="existing-case-summary-top d-flex justify-content-between">
            <h5>Envelope and Filing Summary</h5>
          </div>
        </div>
      </div>

      <div className="accordion-row">
        <div className="accordion-col">
          <Accordion className="remove-item-css" defaultExpanded>
            <AccordionSummary
              className="accordion-info-title light-grey-bg"
              expandIcon={
                <ExpandMoreIcon className="theme-blue accordion-arrow" />
              }
            >
              <h5 className="theme-blue f-w-bold">Case Information</h5>
              <span
                className="accordions-edit theme-blue f-w-bold"
                onClick={() => changeTab(1)}
              >
                <EditIcon className="vertical-align-middle" /> <span>Edit</span>
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <div className="case-dtl-row">
                <Grid container spacing={4}>
                  <Grid item md={4} sm={12} mt={2}>
                    <p>
                      <span className="d-block">Location</span>
                      <label className="d-block">{caseInfo.jurisdiction}</label>
                    </p>
                  </Grid>
                  <Grid item md={4} sm={12} mt={2}>
                    <p>
                      <span className="d-block">Case Category</span>
                      <label className="d-block">{caseInfo.caseCategory}</label>
                    </p>
                  </Grid>
                  <Grid item md={4} sm={12} mt={2}>
                    <p>
                      <span className="d-block">Case Type</span>
                      <label className="d-block">{caseInfo.caseType}</label>
                    </p>
                  </Grid>
                  <Grid item md={4} sm={12} mt={2}>
                    <p>
                      <span className="d-block">Filing Attorney</span>
                      <label className="d-block">{attorneyName}</label>
                    </p>
                  </Grid>
                  <Grid item md={4} sm={12} mt={2}>
                    <p>
                      <span className="d-block">Payment Account</span>
                      <label className="d-block">
                        {
                          paymentAccounts?.find(
                            (paymentAccount) =>
                              paymentAccount.paymentAccountID ===
                              filingInfo.paymentAccount
                          )?.accountName
                        }
                      </label>
                    </p>
                  </Grid>
                  <Grid item md={4} sm={12} mt={2}>
                    <p>
                      <span className="d-block">Date Filed</span>
                      <label className="d-block">-</label>
                    </p>
                  </Grid>
                  {Boolean(crossReferenceNumbers?.length) && (
                    <Grid item xs={12}>
                      <GridTable
                        columns={crossReferenceColumns}
                        rows={crossReferenceNumbers}
                        hideFooter={false}
                        getRowId={(row) => row.efmCrossreferencecodeCode}
                      />
                    </Grid>
                  )}
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="accordion-col">
          <Accordion className="remove-item-css">
            <AccordionSummary
              className="accordion-info-title light-grey-bg"
              expandIcon={
                <ExpandMoreIcon className="theme-blue accordion-arrow" />
              }
            >
              <h5 className="theme-blue f-w-bold">Parties</h5>
              <span
                className="accordions-edit theme-blue f-w-bold"
                onClick={() => changeTab(1)}
              >
                <EditIcon className="vertical-align-middle" /> <span>Edit</span>
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <GridTable
                columns={partyColumns}
                rows={parties}
                hideFooter={false}
                getRowId={(row) => row.code}
              />
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="accordion-col">
          <Accordion className="remove-item-css">
            <AccordionSummary
              className="accordion-info-title light-grey-bg"
              expandIcon={
                <ExpandMoreIcon className="theme-blue accordion-arrow" />
              }
            >
              <h5 className="theme-blue f-w-bold">Filings</h5>
              <span
                className="accordions-edit theme-blue f-w-bold"
                onClick={() => changeTab(2)}
              >
                <EditIcon className="vertical-align-middle" /> <span>Edit</span>
              </span>
            </AccordionSummary>
            <AccordionDetails>
              {filingInfo.filings?.map((filing) => (
                <Box
                  border={1}
                  paddingX={2}
                  marginBottom={2}
                  paddingBottom={2}
                  borderColor="#d9d9d9"
                  borderRadius={2}
                >
                  <div className="case-dtl-row">
                    <Grid container spacing={4}>
                      <Grid item sm={3} xs={6} mt={2}>
                        <p>
                          <span className="d-block">Filing Code</span>
                          <label className="d-block">
                            {
                              filingTypes?.find(
                                (filingType) =>
                                  filingType.efmFilingtypeCode ===
                                  filing.filingCode
                              )?.efmFilingtypeName
                            }
                          </label>
                        </p>
                      </Grid>
                      <Grid item sm={3} xs={6} mt={2}>
                        <p>
                          <span className="d-block">Filing Description</span>
                          <label className="d-block">
                            {filing.documentDescriptionText}
                          </label>
                        </p>
                      </Grid>
                      <Grid item sm={3} xs={6} mt={2}>
                        <p>
                          <span className="d-block">Reference Number</span>
                          <label className="d-block">
                            {filing.referenceNumber}
                          </label>
                        </p>
                      </Grid>
                      <Grid item sm={3} xs={6} mt={2}>
                        <p>
                          <span className="d-block">Filing Type</span>
                          <label className="d-block">
                            {filing.eFile && filing.service
                              ? "EFileAndServe"
                              : !filing.eFile && filing.service
                              ? "Serve"
                              : "EFile"}
                          </label>
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                  <ComponentSummary
                    locationCode={caseInfo.locationId}
                    filingTypeCode={filing.filingCode}
                    attachments={filing.attachments}
                    draftEnvelopeId = {draftEnvelopeId}
                  />
                  <div className="case-dtl-row">
                    <Grid container spacing={4}>
                      <Grid item xs={12} mt={2}>
                        <p>
                          <span className="d-block">Courtesy Copies</span>
                          <label className="d-block">
                            {filing.courtesyCopiesText}
                          </label>
                        </p>
                      </Grid>
                      <Grid item xs={12} className="pt-3">
                        <p>
                          <span className="d-block">Filing Comments</span>
                          <label className="d-block">
                            {filing.filingCommentsText}
                          </label>
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>

        {Boolean(selectedServiceContacts.length) && (
          <ServiceContacts
            caseInfo={caseInfo}
            serviceContacts={serviceContacts}
            selectedServiceContacts={selectedServiceContacts}
            changeTab={changeTab}
          />
        )}

        <div className="accordion-col">
          {Boolean(
            locations?.find(
              (location) => location.efm_Location_Code === caseInfo.locationId
            )?.isreturndate
          ) && (
            <Accordion className="remove-item-css">
              <AccordionSummary
                className="accordion-info-title light-grey-bg"
                expandIcon={
                  <ExpandMoreIcon className="theme-blue accordion-arrow" />
                }
              >
                <h5 className="theme-blue f-w-bold">Return Date</h5>
              </AccordionSummary>
              <AccordionDetails>
                {filingInfo.returnDate
                  ? format(filingInfo.returnDate, "MM/dd/yyyy")
                  : "Return Date Not Applicable"}
              </AccordionDetails>
            </Accordion>
          )}
        </div>

        {Boolean(disclaimerRequirement?.length) && (
          <div className="accordion-col">
            <Accordion className="remove-item-css">
              <AccordionSummary
                className="accordion-info-title light-grey-bg"
                expandIcon={
                  <ExpandMoreIcon className="theme-blue accordion-arrow" />
                }
              >
                <h5 className="theme-blue f-w-bold">Submission Agreements</h5>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  border={1}
                  paddingX={2}
                  marginBottom={2}
                  paddingBottom={2}
                  borderColor="#d9d9d9"
                  borderRadius={2}
                >
                  {disclaimerRequirement?.map((node) => (
                    <div className="p-2" key={node.efmDisclaimerCode}>
                      {node.requirementtext}
                    </div>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </div>

      <div className="payment-fees">
        <Grid container spacing={4}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <h4 className="mb-3">
              <MonetizationOnIcon className="payment-icon vertical-align-middle red-pastel" />{" "}
              Payment & Fees
            </h4>

            {filingInfo.filings.some(
              (filing) =>
                filing.filingCode && filing.filingCode !== "SERVICE ONLY"
            ) && (
              <TextField
                className="w-100 mb-4"
                id="paymentAccount"
                InputLabelProps={{
                  shrink: true,
                  required: true,
                  htmlFor: "paymentAccount_id",
                }}
                SelectProps={{
                  displayEmpty: true,
                  SelectDisplayProps: {
                    "aria-required": true,
                  },
                }}
                inputProps={{
                  id: "paymentAccount_id",
                }}
                value={paymentAccount}
                onChange={updatePaymentAccount}
                select
                placeholder="Select Payment Account"
                label="Select Payment Account"
                variant="standard"
              >
                <MenuItem value="" disabled>
                  Select Payment Account
                </MenuItem>
                {paymentAccounts?.map((paymentAccount) => (
                  <MenuItem
                    key={paymentAccount.paymentAccountID}
                    value={paymentAccount.paymentAccountID}
                  >
                    {paymentAccount.accountName}
                  </MenuItem>
                ))}
              </TextField>
            )}

            {!isIndividualUser() && (
              <TextField
                className="w-100 mb-4"
                id="filingAttorneyName"
                InputLabelProps={{
                  shrink: true,
                  required: true,
                  htmlFor: "filingAttorneyName_id",
                }}
                SelectProps={{
                  displayEmpty: true,
                  SelectDisplayProps: {
                    "aria-required": true,
                  },
                }}
                inputProps={{
                  id: "filingAttorneyName_id",
                }}
                value={filingAttorney}
                onChange={updateFilingAttorney}
                select
                placeholder="Select Filing Attorney"
                label="Select Filing Attorney"
                variant="standard"
              >
                <MenuItem value="" disabled>
                  Select Filing Attorney
                </MenuItem>
                {attorneys?.map((attorney) => (
                  <MenuItem
                    key={attorney.attorneyID}
                    value={attorney.attorneyID}
                  >
                    {`${attorney.firstName} ${attorney.lastName}`}
                  </MenuItem>
                ))}
              </TextField>
            )}

            {!isWaiverPayment &&
              filingInfo.filings.some(
                (filing) =>
                  filing.filingCode && filing.filingCode !== "SERVICE ONLY"
              ) && (
                <TextField
                  className="w-100"
                  id="partyResponsibleForFees"
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                    htmlFor: "partyResponsibleForFees_id",
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    SelectDisplayProps: {
                      "aria-required": true,
                    },
                  }}
                  inputProps={{
                    id: "partyResponsibleForFees_id",
                  }}
                  value={partyResponsibleForFees}
                  onChange={updatePartyResponsibleForFees}
                  select
                  placeholder="Party Responsible for Fees"
                  label="Party Responsible for Fees"
                  variant="standard"
                >
                  <MenuItem value="">Select Party </MenuItem>
                  {parties?.map((party, index) => (
                    <MenuItem key={`${party.code}-${index}`} value={index + 1}>
                      {party.firstName || party.lastName || party.businessName
                        ? party.type === "person"
                          ? `${party.firstName} ${party.lastName}`
                          : `${party.businessName}`
                        : party.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box sx={{ p: 3 }} className="payment-box">
              <div className="payment-box-row">
                <Grid container>
                  {filingInfo.filingFees.map((filingFee, index) => (
                    <>
                      <Grid item xs={6}>
                        {filingFee.filingTypeName}
                      </Grid>

                      <Grid item xs={6}>
                        <div className="d-flex justify-content-between mb-3">
                          <span className="w-50">Filing Fee</span>
                          <p>
                            ${" "}
                            {renderAmount(
                              isWaiverPayment ? 0 : filingFee.amount
                            )}
                          </p>
                        </div>

                        {filingFee.optionalServices.map((optionalService) => (
                          <div
                            key={optionalService.efmOptionalservicCode}
                            className="mb-3 d-flex justify-content-between"
                          >
                            <span className="w-50">
                              {optionalService.efmOptionalserviceName}
                            </span>
                            <p>
                              ${" "}
                              {renderAmount(
                                isWaiverPayment ? 0 : optionalService.fee
                              )}
                            </p>
                          </div>
                        ))}

                        <div className="d-flex justify-content-between">
                          <p className="w-50">
                            <strong>Total</strong>
                          </p>
                          <p>
                            <strong>
                              ${" "}
                              {renderAmount(
                                isWaiverPayment ? 0 : filingFee.total
                              )}
                            </strong>
                          </p>
                        </div>
                      </Grid>

                      {index + 1 < filingInfo.filingFees.length && (
                        <Grid item xs={12} paddingY={2}>
                          <hr />
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
              </div>
              <div className="payment-box-row d-flex justify-content-between">
                <span>Case Initiation Fee</span>
                <p>
                  ${" "}
                  {renderAmount(
                    isWaiverPayment ? 0 : filingInfo.caseInitiationFee
                  )}
                </p>
              </div>
              <div className="payment-box-row d-flex justify-content-between">
                <span>I2File Fee</span>
                <p>
                  ${" "}
                  {renderAmount(
                    isWaiverPayment ? 0 : filingInfo.totalProviderFee
                  )}
                </p>
              </div>
              <div className="payment-box-row d-flex justify-content-between">
                <span>Payment Service Fee</span>
                <p>
                  ${" "}
                  {renderAmount(isWaiverPayment ? 0 : filingInfo.conveninceFee)}
                </p>
              </div>
              <div className="payment-box-row d-flex justify-content-between">
                <p>
                  <strong>Envelope Total</strong>
                </p>
                <p>
                  <strong>
                    ${" "}
                    {renderAmount(
                      isWaiverPayment ? 0 : filingInfo.feeCalculationAmount
                    )}
                  </strong>
                </p>
              </div>
            </Box>
          </Grid>
        </Grid>
        <Box pt={4} pb={2} display="flex" justifyContent="center">
          <Button
            className="btn button-outline-secondary me-3"
            variant="contained"
            type="button"
            onClick={() => changeTab(selectedServiceContacts.length ? 3 : 2)}
          >
            Previous
          </Button>

          <Button
            className="btn primary-button"
            variant="contained"
            type="submit"
            disabled={isDisabled}
            onClick={createCase}
          >
            Submit
          </Button>
        </Box>
      </div>
      <ConfirmationDialog
        message={
          envelopeId ? `Envelope ${envelopeId} is Submitting.` : undefined
        }
        isHearingDate={isHearingDate}
        closeModal={goToDashboard}
      />
    </Box>
  );
};

export default Summary;
