import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid/models";

import GridTable from "../../../components/grid";
import ComponentSummary from "./componentSummary";
import ConfirmationDialog from "./confirmationDialog";
import Loader from "../../../components/loader";
import useLocations from "../../../apis/common/useLocations";
import useLocationCaseCategories from "../../../apis/common/useLocationCaseCategories";
import useLocationCaseTypes from "../../../apis/common/useLocationCaseTypes";
import useAttorneys from "../../../apis/firm/useAttorneys";
import usePaymentAccounts from "../../../apis/miscellaneous/usePaymentAccounts";
import useLocationFilingTypes from "../../../apis/common/useLocationFilingTypes";
import useReviewFiling from "../../../apis/newCase/useReviewFiling";
import useDisclaimerRequirement from "../../../apis/common/useDisclaimerRequirement";
import { isIndividualUser, renderAmount } from "../../../utills";
import {
  IAttorney,
  ICaseInformation,
  IFeeCalculationRequest,
  IFilingInformation,
  IParty,
} from "../../../interfaces";
import { NotificationContext } from "../../../context/NotificationContext";

interface ISummary {
  caseInformation: ICaseInformation;
  parties: IParty[];
  filingInformation: IFilingInformation;
  formatToFeeCalculation: (
    paymentAccountId: string,
    filingAttorneyId: string,
    filingsInfo: IFilingInformation,
    partyResponsibleForFees: number | string,
    attorneys?: IAttorney[],
  ) => IFeeCalculationRequest | undefined;
  updatePayment: (
    tabIndex: number,
    paymentAccount: string,
    filingAttorney: string,
    partyResponsibleForFees: number | string
  ) => void;
  draftEnvelopeId: string;
}

const Summary: FC<ISummary> = ({
  caseInformation,
  parties,
  filingInformation,
  draftEnvelopeId,
  formatToFeeCalculation,
  updatePayment,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const { data: locations } = useLocations();
  const { data: caseCategories } = useLocationCaseCategories(
    caseInformation.locationName
  );
  const { data: caseTypes } = useLocationCaseTypes(
    caseInformation.locationName,
    caseInformation.categoryName
  );
  const { data: filingTypes } = useLocationFilingTypes(
    caseInformation.locationName,
    caseInformation.categoryName,
    caseInformation.caseTypeName
  );
  const disclaimerRequirement = useDisclaimerRequirement(
    caseInformation.locationName
  );
  const { data: attorneys } = useAttorneys();
  const { data: paymentAccounts } = usePaymentAccounts();
  const reviewFiling = useReviewFiling();
  const { createNotification } = useContext(NotificationContext);

  const [envelopeId, setEnvelopeId] = useState<number>();
  const [paymentAccount, setPaymentAccount] = useState(
    caseInformation.paymentAccount
  );
  const [filingAttorney, setFilingAttorney] = useState(
    caseInformation.filingAttorney
  );
  const [partyResponsibleForFees, setPartyResponsibleForFees] = useState<
    number | string
  >(filingInformation.partyResponsibleForFeesIndex || "");

  const attorneyName = useMemo(() => {
    const attorney = attorneys?.find(
      (attorney) => attorney.attorneyID === caseInformation.filingAttorney
    );

    if (attorney) return `${attorney.firstName} ${attorney.lastName}`;
    else return "-";
  }, [attorneys, caseInformation.filingAttorney]);

  const [isHearingDate, setIsHearingDate] = useState(false);

  const crossReferenceColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: "efmCrossreferencecodeName",
        headerName: "Case Cross Reference Type",
        width: 750,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "userCrossReferenceNumber",
        headerName: "Case Cross Reference Number",
        width: 700,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
    ],
    []
  );

  const partyColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: "name",
        headerName: "Party Type",
        width: 200,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "party_name",
        headerName: "Name",
        width: 200,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          if (params.row.type === "person")
            return `${params.row.firstName} ${params.row.middleName || ""} ${
              params.row.lastName
            }`;

          return params.row.businessName;
        },
      },
      {
        field: "address",
        headerName: "Address",
        width: 450,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <span>
              {params.row.streetAddressLine1 || ""}
              {params.row.streetAddressLine1 && <br />}
              {params.row.streetAddressLine2
                ? params.row.streetAddressLine2 + " "
                : ""}
              {params.row.city ? params.row.city + ", " : ""}
              {params.row.stateCode ? params.row.stateCode + " " : ""}
              {params.row.zipCode || ""}
            </span>
          );
        },
      },
      {
        field: "phoneNumber",
        headerName: "Phone",
        width: 200,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "leadAttorney",
        headerName: "Attorney",
        width: 200,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          if (!params.row.leadAttorney) return "";

          const attorney = attorneys?.find(
            (attorney) => attorney.attorneyID === params.row.leadAttorney
          );
          if (!attorney) return "";
          return `${attorney.firstName} ${attorney.lastName}`;
        },
      },
    ],
    [attorneys]
  );

  const isWaiverPayment = useMemo(() => {
    return (
      paymentAccounts?.some(
        (item) =>
          item.paymentAccountTypeCode === "WV" &&
          item.paymentAccountID === paymentAccount
      ) || false
    );
  }, [paymentAccount, paymentAccounts]);

  const changeTab = useCallback(
    (tabIndex: number) => {
      updatePayment(
        tabIndex,
        paymentAccount,
        filingAttorney,
        partyResponsibleForFees
      );
    },
    [paymentAccount, filingAttorney, partyResponsibleForFees, updatePayment]
  );

  const updatePaymentAccount = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPaymentAccount(e.target.value);
    },
    []
  );

  const updateFilingAttorney = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFilingAttorney(e.target.value);
    },
    []
  );

  const updatePartyResponsibleForFees = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPartyResponsibleForFees(parseInt(e.target.value));
    },
    []
  );

  const goToDashboard = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  const createCase = useCallback(() => {
    setIsDisabled(!isDisabled);
    if (!paymentAccount) return;

    if (!isIndividualUser() && !attorneys?.length && !filingAttorney) return;

    const requestObject = formatToFeeCalculation(
      paymentAccount,
      filingAttorney,
      filingInformation,
      partyResponsibleForFees,
      attorneys
    );

    if (requestObject) {
      reviewFiling.mutate(requestObject);
    }

    const isHearingDate = locations?.find(
      (node) => node.efm_Location_Code === caseInformation.locationName
    )?.ishearingdate;
    setIsHearingDate(isHearingDate || false);
  }, [
    attorneys,
    paymentAccount,
    filingAttorney,
    filingInformation,
    caseInformation,
    locations,
    partyResponsibleForFees,
    formatToFeeCalculation,
    reviewFiling,
  ]);

  useEffect(() => {
    if (reviewFiling.isSuccess) {
      setEnvelopeId(reviewFiling.data);
    }
    // eslint-disable-next-line
  }, [reviewFiling.isSuccess]);

  useEffect(() => {
    if (reviewFiling.isError) {
      createNotification({
        message:
          // @ts-ignore
          reviewFiling.error?.response?.data?.userMessage ||
          (typeof reviewFiling.error?.response?.data === "string"
            ? reviewFiling.error?.response?.data
            : null) ||
          "An Error occurred while creating new case!",
        type: "error",
        title: "New Case Notification",
      });
    }
    // eslint-disable-next-line
  }, [reviewFiling.isError, createNotification]);

  useEffect(() => {
    if (disclaimerRequirement.isError) {
      createNotification({
        message:
          disclaimerRequirement.error.response?.data?.userMessage ||
          "An Error occurred while getting disclaimer requirements!",
        type: "error",
        title: "New Case Notification",
      });
    }
    // eslint-disable-next-line
  }, [disclaimerRequirement.isError]);

  return (
    <Box sx={{ p: 0 }}>
      {reviewFiling.isLoading && <Loader />}
      <div className="summary-row">
        <div className="existing-case-summary">
          <h3 className="dash-title-h3 mb-3 theme-blue">Summary</h3>
          <div className="existing-case-summary-top d-flex justify-content-between">
            <h5>Envelope and Filing Summary</h5>
          </div>
        </div>
      </div>

      <div className="accordion-row">
        <div className="accordion-col">
          <Accordion className="remove-item-css" defaultExpanded>
            <AccordionSummary
              className="accordion-info-title light-grey-bg"
              expandIcon={
                <ExpandMoreIcon className="theme-blue accordion-arrow" />
              }
            >
              <h5 className="theme-blue f-w-bold">Case Information</h5>
              <span
                className="accordions-edit theme-blue f-w-bold"
                onClick={() => changeTab(0)}
              >
                <EditIcon className="vertical-align-middle" /> <span>Edit</span>
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <div className="case-dtl-row">
                <Grid container spacing={4}>
                  <Grid item md={4} sm={12} mt={2}>
                    <p>
                      <span className="d-block">Location</span>
                      <label className="d-block">
                        {
                          locations?.find(
                            (location) =>
                              location.efm_Location_Code ===
                              caseInformation.locationName
                          )?.efm_Location_Name
                        }
                      </label>
                    </p>
                  </Grid>
                  <Grid item md={4} sm={12} mt={2}>
                    <p>
                      <span className="d-block">Case Category</span>
                      <label className="d-block">
                        {
                          caseCategories?.find(
                            (category) =>
                              category.efmCasecategoryCode ===
                              caseInformation.categoryName
                          )?.efmCasecategoryName
                        }
                      </label>
                    </p>
                  </Grid>
                  <Grid item md={4} sm={12} mt={2}>
                    <p>
                      <span className="d-block">Case Type</span>
                      <label className="d-block">
                        {
                          caseTypes?.find(
                            (caseType) =>
                              caseType.efmCasetypeCode ===
                              caseInformation.caseTypeName
                          )?.efmCasetypeName
                        }
                      </label>
                    </p>
                  </Grid>
                  <Grid item md={4} sm={12} mt={2}>
                    <p>
                      <span className="d-block">Filing Attorney</span>
                      <label className="d-block">{attorneyName}</label>
                    </p>
                  </Grid>
                  <Grid item md={4} sm={12} mt={2}>
                    <p>
                      <span className="d-block">Payment Account</span>
                      <label className="d-block">
                        {
                          paymentAccounts?.find(
                            (paymentAccount) =>
                              paymentAccount.paymentAccountID ===
                              caseInformation.paymentAccount
                          )?.accountName
                        }
                      </label>
                    </p>
                  </Grid>
                  <Grid item md={4} sm={12} mt={2}>
                    <p>
                      <span className="d-block">Date Filed</span>
                      <label className="d-block">-</label>
                    </p>
                  </Grid>
                  {Boolean(
                    caseInformation.caseCrossReferenceNumbers?.length
                  ) && (
                    <Grid item xs={12}>
                      <GridTable
                        columns={crossReferenceColumns}
                        rows={caseInformation.caseCrossReferenceNumbers}
                        hideFooter={false}
                        getRowId={(row) => row.efmCrossreferencecodeCode}
                      />
                    </Grid>
                  )}
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="accordion-col">
          <Accordion className="remove-item-css">
            <AccordionSummary
              className="accordion-info-title light-grey-bg"
              expandIcon={
                <ExpandMoreIcon className="theme-blue accordion-arrow" />
              }
            >
              <h5 className="theme-blue f-w-bold">Parties</h5>
              <span
                className="accordions-edit theme-blue f-w-bold"
                onClick={() => changeTab(1)}
              >
                <EditIcon className="vertical-align-middle" /> <span>Edit</span>
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <GridTable
                columns={partyColumns}
                rows={parties}
                hideFooter={false}
                getRowId={(row) => row.code}
              />
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="accordion-col">
          <Accordion className="remove-item-css">
            <AccordionSummary
              className="accordion-info-title light-grey-bg"
              expandIcon={
                <ExpandMoreIcon className="theme-blue accordion-arrow" />
              }
            >
              <h5 className="theme-blue f-w-bold">Filings</h5>
              <span
                className="accordions-edit theme-blue f-w-bold"
                onClick={() => changeTab(2)}
              >
                <EditIcon className="vertical-align-middle" /> <span>Edit</span>
              </span>
            </AccordionSummary>
            <AccordionDetails>
              {filingInformation.filings?.map((filing) => (
                <Box
                  border={1}
                  paddingX={2}
                  marginBottom={2}
                  paddingBottom={2}
                  borderColor="#d9d9d9"
                  borderRadius={2}
                >
                  <div className="case-dtl-row">
                    <Grid container spacing={4}>
                      <Grid item sm={3} xs={6} mt={2}>
                        <p>
                          <span className="d-block">Filing Code</span>
                          <label className="d-block">
                            {
                              filingTypes?.find(
                                (filingType) =>
                                  filingType.efmFilingtypeCode ===
                                  filing.filingCode
                              )?.efmFilingtypeName
                            }
                          </label>
                        </p>
                      </Grid>
                      <Grid item sm={3} xs={6} mt={2}>
                        <p>
                          <span className="d-block">Filing Description</span>
                          <label className="d-block">
                            {filing.documentDescriptionText}
                          </label>
                        </p>
                      </Grid>
                      <Grid item sm={3} xs={6} mt={2}>
                        <p>
                          <span className="d-block">Reference Number</span>
                          <label className="d-block">
                            {filing.referenceNumber}
                          </label>
                        </p>
                      </Grid>
                      <Grid item sm={3} xs={6} mt={2}>
                        <p>
                          <span className="d-block">Filing Type</span>
                          <label className="d-block">
                            {filing.eFile && filing.service
                              ? "EFileAndServe"
                              : !filing.eFile && filing.service
                              ? "Serve"
                              : "EFile"}
                          </label>
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                  <ComponentSummary
                    locationCode={caseInformation.locationName}
                    filingTypeCode={filing.filingCode}
                    attachments={filing.attachments}
                    draftEnvelopeId={draftEnvelopeId}
                  />
                  <div className="case-dtl-row">
                    <Grid container spacing={4}>
                      <Grid item xs={12} mt={2}>
                        <p>
                          <span className="d-block">Courtesy Copies</span>
                          <label className="d-block">
                            {filing.courtesyCopiesText}
                          </label>
                        </p>
                      </Grid>
                      <Grid item xs={12} className="pt-3">
                        <p>
                          <span className="d-block">Filing Comments</span>
                          <label className="d-block">
                            {filing.filingCommentsText}
                          </label>
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="accordion-col">
          {caseInformation.isReturnDate && (
            <Accordion className="remove-item-css">
              <AccordionSummary
                className="accordion-info-title light-grey-bg"
                expandIcon={
                  <ExpandMoreIcon className="theme-blue accordion-arrow" />
                }
              >
                <h5 className="theme-blue f-w-bold">Return Date</h5>
              </AccordionSummary>
              <AccordionDetails>
                {filingInformation.returnDate
                  ? format(filingInformation.returnDate, "MM/dd/yyyy")
                  : "Return Date Not Applicable"}
              </AccordionDetails>
            </Accordion>
          )}
        </div>

        <div className="accordion-col">
          {Boolean(disclaimerRequirement.data?.length) && (
            <Accordion className="remove-item-css">
              <AccordionSummary
                className="accordion-info-title light-grey-bg"
                expandIcon={
                  <ExpandMoreIcon className="theme-blue accordion-arrow" />
                }
              >
                <h5 className="theme-blue f-w-bold">Submission Agreements</h5>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  border={1}
                  paddingX={2}
                  marginBottom={2}
                  paddingBottom={2}
                  borderColor="#d9d9d9"
                  borderRadius={2}
                >
                  {disclaimerRequirement.data?.map((node) => (
                    <div className="p-2" key={node.efmDisclaimerCode}>
                      {node.requirementtext}
                    </div>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      </div>

      <div className="payment-fees">
        <Grid container spacing={4}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <h4 className="mb-3">
              <MonetizationOnIcon className="payment-icon vertical-align-middle red-pastel" />{" "}
              Payment & Fees
            </h4>

            <TextField
              className="w-100 mb-4"
              id="paymentAccount"
              InputLabelProps={{
                shrink: true,
                required: true,
                htmlFor: "paymentAccount_id",
              }}
              SelectProps={{
                displayEmpty: true,
                SelectDisplayProps: {
                  "aria-required": true,
                },
              }}
              inputProps={{
                id: "paymentAccount_id",
              }}
              value={paymentAccount}
              onChange={updatePaymentAccount}
              select
              placeholder="Select Payment Account"
              label="Select Payment Account"
              variant="standard"
            >
              <MenuItem value="" disabled>
                Select Payment Account
              </MenuItem>
              {paymentAccounts?.map((paymentAccount) => (
                <MenuItem
                  key={paymentAccount.paymentAccountID}
                  value={paymentAccount.paymentAccountID}
                >
                  {paymentAccount.accountName}
                </MenuItem>
              ))}
            </TextField>

            {!isIndividualUser() && (
              <TextField
                className="w-100 mb-4"
                id="filingAttorneyName"
                InputLabelProps={{
                  shrink: true,
                  required: true,
                  htmlFor: "filingAttorneyName_id",
                }}
                SelectProps={{
                  displayEmpty: true,
                  SelectDisplayProps: {
                    "aria-required": true,
                  },
                }}
                inputProps={{
                  id: "filingAttorneyName_id",
                }}
                value={filingAttorney}
                onChange={updateFilingAttorney}
                select
                placeholder="Select Filing Attorney"
                label="Select Filing Attorney"
                variant="standard"
              >
                <MenuItem value="" disabled>
                  Select Filing Attorney
                </MenuItem>
                {attorneys?.map((attorney) => (
                  <MenuItem
                    key={attorney.attorneyID}
                    value={attorney.attorneyID}
                  >
                    {`${attorney.firstName} ${attorney.lastName}`}
                  </MenuItem>
                ))}
              </TextField>
            )}

            {!isWaiverPayment && (
              <TextField
                className="w-100"
                id="partyResponsibleForFees"
                InputLabelProps={{
                  shrink: true,
                  required: true,
                  htmlFor: "partyResponsibleForFees_id",
                }}
                SelectProps={{
                  displayEmpty: true,
                  SelectDisplayProps: {
                    "aria-required": true,
                  },
                }}
                inputProps={{
                  id: "partyResponsibleForFees_id",
                }}
                value={partyResponsibleForFees}
                onChange={updatePartyResponsibleForFees}
                select
                placeholder="Party Responsible for Fees"
                label="Party Responsible for Fees"
                variant="standard"
              >
                <MenuItem value="">Select Party </MenuItem>
                {parties?.map((party, index) => (
                  <MenuItem key={`${party.code}-${index}`} value={index + 1}>
                    {party.type === "person"
                      ? `${party.firstName} ${party.lastName}`
                      : `${party.businessName}`}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box sx={{ p: 3 }} className="payment-box">
              {/* {feeCalculation.isLoading && (
                <Loader className="section-loader" />
              )} */}
              <div className="payment-box-row">
                <Grid container>
                  {filingInformation.filingFees.map((filingFee, index) => (
                    <>
                      <Grid item xs={6}>
                        {filingFee.filingTypeName}
                      </Grid>

                      <Grid item xs={6}>
                        <div className="d-flex justify-content-between mb-3">
                          <span className="w-50">Filing Fee</span>
                          <p>
                            ${" "}
                            {renderAmount(
                              isWaiverPayment ? 0 : filingFee.amount
                            )}
                          </p>
                        </div>

                        {filingFee.optionalServices.map((optionalService) => (
                          <div
                            key={optionalService.efmOptionalservicCode}
                            className="mb-3 d-flex justify-content-between"
                          >
                            <span className="w-50">
                              {optionalService.efmOptionalserviceName}
                            </span>
                            <p>
                              ${" "}
                              {renderAmount(
                                isWaiverPayment ? 0 : optionalService.fee
                              )}
                            </p>
                          </div>
                        ))}

                        <div className="d-flex justify-content-between">
                          <p className="w-50">
                            <strong>Total</strong>
                          </p>
                          <p>
                            <strong>
                              ${" "}
                              {renderAmount(
                                isWaiverPayment ? 0 : filingFee.total
                              )}
                            </strong>
                          </p>
                        </div>
                      </Grid>

                      {index + 1 < filingInformation.filingFees.length && (
                        <Grid item xs={12} paddingY={2}>
                          <hr />
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
              </div>
              <div className="payment-box-row d-flex justify-content-between">
                <span>Case Initiation Fee</span>
                <p>
                  ${" "}
                  {renderAmount(
                    isWaiverPayment ? 0 : filingInformation.caseInitiationFee
                  )}
                </p>
              </div>
              <div className="payment-box-row d-flex justify-content-between">
                <span>I2File Fee</span>
                <p>
                  ${" "}
                  {renderAmount(
                    isWaiverPayment ? 0 : filingInformation.totalProviderFee
                  )}
                </p>
              </div>
              <div className="payment-box-row d-flex justify-content-between">
                <span>Payment Service Fee</span>
                <p>
                  ${" "}
                  {renderAmount(
                    isWaiverPayment ? 0 : filingInformation.conveninceFee
                  )}
                </p>
              </div>
              <div className="payment-box-row d-flex justify-content-between">
                <p>
                  <strong>Envelope Total</strong>
                </p>
                <p>
                  <strong>
                    ${" "}
                    {renderAmount(
                      isWaiverPayment
                        ? 0
                        : filingInformation.feeCalculationAmount
                    )}
                  </strong>
                </p>
              </div>
            </Box>
          </Grid>
        </Grid>
        <Box pt={4} pb={2} display="flex" justifyContent="center">
          <Button
            className="btn button-outline-secondary me-3"
            variant="contained"
            type="button"
            onClick={() => changeTab(2)}
          >
            Previous
          </Button>

          <Button
            className="btn primary-button"
            variant="contained"
            type="submit"
            disabled={isDisabled}
            onClick={createCase}
          >
            Submit
          </Button>
        </Box>
      </div>

      <ConfirmationDialog
        message={
          envelopeId ? `Envelope ${envelopeId} is Submitting.` : undefined
        }
        isHearingDate={isHearingDate}
        closeModal={goToDashboard}
      />
    </Box>
  );
};

export default Summary;
